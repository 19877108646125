import React from 'react'
import { Route, Switch, withRouter } from 'react-router-dom'
import { observer } from 'mobx-react'

import Home from './Home'
import Roster from './Roster'
import vinrar from './Players/vinrar'

import Events from './Events'
import NZEHL22 from './Events/NZEHL22'

import AppBar from '@mui/material/AppBar'
import Drawer from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Toolbar from '@mui/material/Toolbar'

import DashboardIcon from '@mui/icons-material/Dashboard'
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import GroupsIcon from '@mui/icons-material/Groups';
import MenuIcon from '@mui/icons-material/Menu'

class Component extends React.Component{

    constructor(props){
        super(props)
        this.state = {
            drawerOpen: false,
        }
    }

    toggleDrawer = () => {
        this.setState({drawerOpen: !this.state.drawerOpen})
    }

    render(){
        return (
            <div className='background-container'>
                <AppBar position='sticky'>
                    <Toolbar variant='dense' className='client-top-style'>
                        <IconButton edge='start' color='inherit' aria-label='menu' onClick={this.toggleDrawer}>
                            <MenuIcon/>
                        </IconButton>
                        <h4 className='title-text'>
                            TEAM ULTRA INSTINCT
                        </h4>
                    </Toolbar>
                </AppBar>
                <Drawer
                    variant='temporary'
                    anchor='left'
                    open={this.state.drawerOpen}
                    ModalProps={{ onBackdropClick: this.toggleDrawer }}
                    PaperProps={{
                        sx: {
                            width: 260,
                            backgroundColor: "rgb(3,14,25)",
                            borderRightStyle: "solid",
                            borderRightColor: "rgb(120,90,40)"
                        }
                    }}
                >
                    <List className='client-menu-style'>
                        <ListItem button onClick={() => {this.props.history.push('/'); this.toggleDrawer()}}>
                            <ListItemIcon>
                                <DashboardIcon className='icon'/>
                            </ListItemIcon>
                            <ListItemText>Home</ListItemText>
                        </ListItem>
                        <ListItem button onClick={() => {this.props.history.push('/roster'); this.toggleDrawer()}}>
                            <ListItemIcon>
                                <GroupsIcon className='icon'/>
                            </ListItemIcon>
                            <ListItemText>Roster</ListItemText>
                        </ListItem>
                        <ListItem button onClick={() => {this.props.history.push('/events'); this.toggleDrawer()}}>
                            <ListItemIcon>
                                <EmojiEventsIcon className='icon'/>
                            </ListItemIcon>
                            <ListItemText>Events</ListItemText>
                        </ListItem>
                    </List>
                </Drawer>
                <Switch>
                    <Route path='/roster' component={Roster} />
                    <Route path='/vinrar' component={vinrar} />
                    <Route path='/events' component={Events} />
                    <Route path='/nzehl22' component={NZEHL22} />
                    <Route path='/*' component={Home} />
                </Switch>
            </div>
        )
    }
}

export default withRouter(observer(Component))