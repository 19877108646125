import React from 'react'
import { withRouter } from 'react-router-dom'
import { observer } from 'mobx-react'

class Component extends React.Component{

    render(){
        return (
            <div>
                <div className='image-container'>
                    <img className='left-padded' src='roster.png' alt='Roster Link' onClick={() => {this.props.history.push('/roster');}}></img>
                    <img className='left-padded' src='events.png' alt='Events Link' onClick={() => {this.props.history.push('/events');}}></img>
                    <img className='left-padded' src='nzesports.png' alt='NZ Esports Link' onClick={() => {window.location.href = 'https://www.nzesports.org.nz/';}}></img>
                </div>
            </div>
        )
    }
}

export default withRouter(observer(Component))