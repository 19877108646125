import React from 'react'
import { withRouter } from 'react-router-dom'
import { observer } from 'mobx-react'

class Component extends React.Component{

    render(){
        return (
            <div className='left-padded'>
                <div className='image-container'>
                    <img className='left-padded' src='nzesports.png' alt='Recap Link' onClick={() => {window.open('https://www.nzesports.org.nz/nz-esports-hyperfibre-league-finals-recap/');}}></img>
                    <img className='left-padded' src='youtube.png' alt='Youtube Link' onClick={() => {window.open('https://www.youtube.com/watch?v=YuiSBC5P4zw&list=PLwn5e9auZ6qNDH8khwXKSQEp5ODMTyhxo');}}></img>
                </div>
            </div>
        )
    }
}

export default withRouter(observer(Component))