import React from 'react'
import { withRouter } from 'react-router-dom'
import { observer } from 'mobx-react'

class Component extends React.Component{

    render(){
        return (
            <div className='left-padded'>
                <h3 className='title-text'>MAIN ROSTER</h3>
                <div className='image-container'>
                    <img src='vinrar.gif' alt='vinrar' onClick={() => {window.open('https://www.op.gg/summoners/oce/vinrar');}}></img>
                    <img src='captaintofu.gif' alt='captaintofu' onClick={() => {window.open('https://www.op.gg/summoners/oce/captaintofu');}}></img>
                    <img src='azula.gif' alt='azula' onClick={() => {window.open('https://www.op.gg/summoners/oce/azula');}}></img>
                    <img src='acrimonious.gif' alt='acrimonious' onClick={() => {window.open('https://www.op.gg/summoners/oce/acrimonious');}}></img>
                    <img src='busk.gif' alt='busk' onClick={() => {window.open('https://www.op.gg/summoners/oce/busk');}}></img>
                    <img src='findmedusa.gif' alt='findmedusa' onClick={() => {window.open('https://www.op.gg/summoners/oce/findmedusa');}}></img>
                </div>
                <h3 className='title-text'>TUI ACADEMY</h3>
                <div className='image-container'>
                    <img src='khonsu.gif' alt='khonsu' onClick={() => {window.open('https://www.op.gg/summoners/oce/khonsu');}}></img>
                    <img src='meloaery.gif' alt='meloaery' onClick={() => {window.open('https://www.op.gg/summoners/oce/meloaery');}}></img>
                </div>
            </div>
        )
    }
}

export default withRouter(observer(Component))