import React from 'react'
import { withRouter } from 'react-router-dom'
import { observer } from 'mobx-react'

class Component extends React.Component{

    render(){
        return (
            <div>
                <div className='image-container'>
                    <img className='left-padded' src='nzehl22.png' alt='Roster Link' onClick={() => {this.props.history.push('/nzehl22');}}></img>
                </div>
            </div>
        )
    }
}

export default withRouter(observer(Component))