import React from 'react'
import { withRouter } from 'react-router-dom'
import { observer } from 'mobx-react'

class Component extends React.Component{

    render(){
        return (
            <div>
                <iframe className='sheet' src="https://docs.google.com/spreadsheets/d/e/2PACX-1vQ_2EWRhp8zU96djCrmuGp6wx5V7Si9ZUAfYaLMTd91_UVzPdYcKdGt3TNu1iMii4WB2PhEzNjc_F_2/pubhtml?gid=0&amp;single=true&amp;widget=true&amp;headers=false"></iframe>
            </div>
        )
    }
}

export default withRouter(observer(Component))