import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'

import './App.css'

import Dashboard from './Dashboard'

const App = () => (
  <BrowserRouter>
    <Switch>
      <Route path='/*' component={Dashboard} />
    </Switch>
  </BrowserRouter>
)

export default App;
